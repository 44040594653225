import React from "react"
import "./header.styles.scss"
import { graphql, useStaticQuery } from "gatsby"
import HeaderImage from "./Images/Header"
import { MdArrowDownward } from "react-icons/md"
import Layout from "./Layout"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      <link
        href="https://fonts.googleapis.com/css?family=Anonymous+Pro|Open+Sans&display=swap"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"
        integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr"
        crossorigin="anonymous"
      />
      <div className="header-main">
        <div className="dev">
          <h1 className="header-title">{data.site.siteMetadata.title}</h1>
          <div className="header-image">
            <HeaderImage imgStyle={{ borderRadius: "50%" }} />
          </div>
          <div className="skills-row">
            <p className="attr">Developer</p>
            <p className="dot">|</p>
            <p className="attr">Marketer</p>
            <p className="dot">|</p>
            <p className="attr">Writer</p>
            <p className="dot">|</p>
            <p className="attr">Consultant</p>
          </div>
        </div>
        <div className="icons">
          <a href="https://github.com/wchesh24" target="_blank">
            <i className="fab fa-github"></i>
          </a>
          <a href="https://twitter.com/TheChesh24" target="_blank">
            <i className="fab fa-twitter"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/william-cheshier-a23b7595/"
            target="_blank"
          >
            <i className="fab fa-linkedin"></i>
          </a>
          <a
            href="https://www.facebook.com/will.cheshier.7?ref=bookmarks"
            target="_blank"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <h2 className="intro-text">Check out some of my cool projects</h2>
          <div className="bounce">
            <MdArrowDownward size={50} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
