import React from "react"
import Layout from "../components/Layout"
import "./index.styles.scss"
import Header from "../components/Header"
import { Helmet } from "react-helmet"
import PageTransition from "gatsby-plugin-page-transitions"
import Link from "gatsby-link"
import Expensify from "../components/Images/Expensify"
import Indecision from "../components/Images/Indecision"
import Parakeet from "../components/Images/Parakeet"
import Omnifood from "../components/Images/Omnifood"

const Index = ({ data }) => {
  console.log(data)
  return (
    <PageTransition
      defaultStyle={{
        transition: "top 700ms cubic-bezier(0.47, 0, 0.75, 0.72)",
        top: "100%",
        position: "absolute",
        width: "100%",
      }}
      transitionStyles={{
        entering: { top: "0%" },
        entered: { top: "0%" },
        exiting: { top: "100%" },
      }}
      transitionTime={500}
    >
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>William Cheshier</title>
          <link rel="canonical" href="https://williamcheshier.com" />
        </Helmet>
        <Header />
        <div className="work-container">
          <h1 className="opener">Recent Work</h1>
          <div className="home-grid">
            <div className="wrapper">
              <Link to="/applications">
                <Parakeet />
              </Link>
              <div className="box">
                <Link className="box-button" to="/applications">
                  More Info
                </Link>
              </div>
            </div>
            <div className="wrapper">
              <Link to="/applications">
                <Indecision />
              </Link>
              <div className="box">
                <Link className="box-button" to="/applications">
                  More Info
                </Link>
              </div>
            </div>
            <div className="wrapper">
              <Link to="/websites">
                <Omnifood />
              </Link>
              <div className="box">
                <Link className="box-button" to="/websites">
                  More Info
                </Link>
              </div>
            </div>
            <div className="wrapper">
              <Link to="/applications">
                <Expensify />
              </Link>

              <div className="box">
                <Link className="box-button" to="/applications">
                  More Info
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </PageTransition>
  )
}

export default Index
